<template>
  <Toast />
  <div class="surface-0 align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden painel">
    <div class="layout-header">
      <Menubar :model="null">
        <template #start>
          <img alt="logo" src="../../../public/images/sespa/brasaoSespa.png" height="70" class="p-mr-4 logo-menu-left" />
        </template>
        <template #end>
          <img alt="logo" src="../../../public/images/sespa/logoEstado.svg" height="50" class="p-mr-4 logo-menu-right" />
        </template>
      </Menubar>
    </div>
    <div class="grid justify-content-center p-2 lg:p-0">
      <div class="col-12 xl:col-4">
        <div class="h-full w-full m-0 py-8 px-4">
          <form @submit.prevent="signIn" class="col-12 mt-5">
            <div class="h-full w-full m-0 py-7 px-4 shadow-3 pnl-form">
              <div class="text-center mb-5">
                <img alt="logo" src="../../../public/images/sespa/piso-enfermagem.png" height="50"
                  class="p-mr-4 logo-menu-right" />
              </div>

              <div class="text-center mb-5">
              </div>
              <div class="w-full md:w-10 mx-auto">
                <label for="login" class="block text-900 text-xl font-medium mb-2">E-mail</label>
                <InputText id="login" v-model.trim="user.email" type="text" class="w-full mb-3"
                  placeholder="Digite seu e-mail" style="padding: 1rem" required />
                <label for="password1" class="block text-900 font-medium text-xl mb-2">Senha</label>
                <Password id="password1" v-model.trim="user.senha" placeholder="Digite sua senha" :toggleMask="true"
                  :feedback="false" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem" required></Password>

                <div class="flex align-items-center justify-content-between mb-5">
                  <a class="font-medium no-underline ml-2 text-left cursor-pointer" style="color: var(--gray-900)"
                    @click="onCreateUser">Cadastrar-se</a>
                  <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--gray-900)"
                    @click="onFoggetPassword">Esqueceu a senha?</a>
                </div>
                <div class="flex align-items-center justify-content-between mb-5">
                  <a class="font-medium no-underline ml-2 text-left cursor-pointer"
                    href="http://www.saude.pa.gov.br/wp-content/uploads/2024/05/Manual-piso-operador-v3.pdf" target="_blank"
                    download="manual.pdf" style="color: var(--gray-900)">Manual de Utilização</a>
                </div>

                <Button label="Entrar" class="p-button-secondary w-full p-3 text-xl" type="submit"></Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="layout-footer fix">
    <span class="footer-text mb-4">&copy; Todos os Direitos. Coordenação de Tecnologia e Informática em
      Saúde - CTIS. <br />
      Tva. Lomas Valentinas, 2190, Bairro Marco, CEP: 66093-677, Belém, Pará
      Contato: +55, 91 4006-4399 | 4006-4328 | <b>www.saude.pa.gov.br</b>
    </span>
  </div>
  <DialogCreate />
  <DialogFoggetPassword />
</template>

<script>
//Models
import Users from "../../models/usuario";

//Services
import AuthService from "../../service/auth/auth_service";

//Components
import DialogCreate from "./components/DialogCreate.vue";
import DialogFoggetPassword from "./components/DialogFoggetPassword.vue";

export default {
  components: {
    DialogCreate,
    DialogFoggetPassword,
  },
  data() {
    return {
      authService: new AuthService(),
      user: new Users(),
      link: process.env.NODE_ENV === "production"
        ? window.location.origin + "/enfermagem/download/manual.pdf"
        : "http://localhost:8081/download/manual.pdf",
    };
  },
  created() {
    if (sessionStorage.getItem("token")) {
      this.$Token.checkToken();
      this.$router.push("/system");
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    signIn() {
      this.authService
        .login(this.user.email, this.user.senha)
        .then(() => this.$router.push("/system"))
        .catch((res) => {
          if ((res.response.status === 400) && (res.response.data.error_description === "Usu&aacute;rio desabilitado")) {
            this.$toast.add({
              severity: "warn",
              summary: "Alerta!",
              detail: "Usuario desabilitado entre em contato com administrador para habilitá-lo."
            });
          }


          if (res.response.status >= 500) {

            this.$toast.add({
              severity: "error",
              summary: "Alerta!",
              detail: "Entre com contato com o CTIS!",
              life: 6000,
            });


          }


          if ((res.response.status === 400) && (res.response.data.error_description === "Usu&aacute;rio inexistente ou senha inv&aacute;lida")) {
            this.$toast.add({
              severity: "error",
              summary: "Alerta!",
              detail: "Login ou senha incorretos!",
              life: 6000,
            });
          }


          /*
          this.$toast.add({
            severity: "error",
            summary: "Alerta!",
            detail: "Login ou senha incorretos!",
            life: 6000,
          });
          */
        });
    },
    onCreateUser() {
      this.$store.state.views.login.dialogCreate = true;
    },
    onFoggetPassword() {
      this.$store.state.views.login.dialogFoggetPassword = true;
    },
  },
};
</script>

<style scoped>
.fix {
  bottom: 0;
  position: fixed;
  width: 100%;
  text-align: center;
}

.grid {
  padding: 0;
  margin: 0;
}

.layout-footer {
  background-color: #dddddd;
  box-shadow: 0px 6px 6px rgba(4, 83, 66, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  bottom: 0;
  height: 70px;
}

.layout-header {
  background-color: #dddddd;
  box-shadow: 0px 6px 6px rgba(124, 125, 128, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  bottom: 0;
}

.painel {
  height: 98vh;
  background-image: url("../../../public/images/sespa/fundo.jpg");
  background-repeat: no-repeat;
  background-size: 100% 120%;
  padding: 0;
  margin: 0;
  animation: fade-in 5s;
}

.pnl-form {
  background-color: #ffffff;
  background-image: #ffffff;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  /*animation: go-back 3s;*/
}

@media screen and (max-width: 992px) {
  .logo-menu-right {
    display: none;
  }

  .logo-menu-left {
    margin-left: 40%;
  }

  .footer-text {
    font-size: 10px;
    margin-bottom: 5%;
  }
}

@media screen and (min-width: 992px) {}

@keyframes go-back {
  from {
    transform: translateX(500px);
  }

  to {
    transform: translateX(100px);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
