<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: widthDialog }"
    header="Cadastro de Usuário"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
  >
    <div class="field">
      <label for="cnes">CNES</label>
      <InputText
        id="cnes"
        v-model="estabelecimento.cnes"
        placeholder="Digite o CNES da sua instituição."
        :disabled="showDetails"
        :class="{
          'p-invalid': submitted && !estabelecimento.cnes,
        }"
      />
      <small class="p-error" v-if="submitted && !estabelecimento.cnes">
        Atenção! Para prosseguir informe um CNES válido.
      </small>
    </div>
    <div v-if="showDetails">
      <Fieldset legend="Criar Usuário">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12">
            <label for="nome">Nome</label>
            <InputText
              id="nome"
              v-model="v$.user.nome.$model"
              maxlength="255"
              placeholder="Digite nome do usuário."
              :class="{
                'p-invalid': submitted && v$.user.nome.$invalid,
              }"
            />
            <small class="p-error" v-if="submitted && v$.user.nome.$invalid">
              Nome é obrigatório.
            </small>
          </div>

          <div class="field col-12 md:col-12">
            <label for="contato">Contato</label>
            <InputMask
              id="contato"
              v-model="v$.user.contato.$model"
              placeholder="Digite o contato."
              mask="(99) 9 9999-9999"
              :class="{
                'p-invalid': submitted && v$.user.contato.$invalid,
              }"
            />
            <small class="p-error" v-if="submitted && v$.user.contato.$invalid">
              Contato é obrigatório.
            </small>
          </div>

          <div class="field col-12 md:col-12">
            <label for="email">E-mail</label>
            <InputText
              id="email"
              type="email"
              v-model.trim="v$.user.email.$model"
              maxlength="255"
              placeholder="Digite o email"
              :class="{
                'p-invalid': submitted && v$.user.email.$invalid,
              }"
            />
            <small class="p-error" v-if="submitted && v$.user.email.$invalid">
              Digite um e-mail válido.
            </small>
          </div>

          <div class="field col-12 md:col-12">
            <label for="password">Senha</label>
            <Password
              id="password"
              v-model.trim="v$.user.senha.$model"
              placeholder="Digite a senha"
              :toggleMask="true"
              :class="{ 'p-invalid': submitted && v$.user.senha.$invalid }"
            >
              <template #footer="sp">
                {{ sp.level }}
                <Divider />
                <p class="mt-2">Sugestões</p>
                <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                  <li>Pelo menos uma minúscula.</li>
                  <li>Pelo menos uma maiúscula.</li>
                  <li>Pelo menos um numérico.</li>
                  <li>Mínimo de 8 caracteres.</li>
                </ul>
              </template>
            </Password>
            <small class="p-error" v-if="submitted && v$.user.senha.$invalid">
              Senha é obrigatório.
            </small>
          </div>

          <div class="field col-12 md:col-12">
            <label for="password">Confirmar Senha</label>
            <Password
              id="password"
              v-model.trim="confirmPasword"
              placeholder="Confirme a senha"
              toggleMask
              :class="{
                'p-invalid':
                  submitted && confirmPasword !== v$.user.senha.$model,
              }"
            />
            <small
              class="p-error"
              v-if="submitted && confirmPasword !== v$.user.senha.$model"
            >
              As senhas devem ser iguais
            </small>
          </div>
        </div>
      </Fieldset>
    </div>
    <template #footer>
      <Button
        label="Pesquisar"
        class="p-button-secondary"
        icon="pi pi-search"
        v-if="!showDetails"
        @click="searchEstabelecimento"
      />
      <Button
        label="Cadastrar"
        class="p-button-success"
        icon="pi pi pi-check"
        v-if="showDetails"
        @click="createUser(!v$.user.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>
<script>
//Models
import Estabelecimento from "../../../models/estabelecimento";
import Users from "../../../models/usuario";

//Services
import EstabelecimentoService from "../../../service/estabelecimento/estabelecimento_service";
import UsersService from "../../../service/user/user_service";

//VALIDATIONS
import { sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {},
  data() {
    return {
      user: new Users(),
      userService: new UsersService(),
      estabelecimento: new Estabelecimento(),
      estabelecimentoService: new EstabelecimentoService(),
      submitted: false,
      confirmPasword: null,
      showDetails: false,
      widthDialog: "480px",
      sameAs,
    };
  },
  validations() {
    return {
      user: this.user.validationsCreate(),
      confirmPassword: this.sameAs(this.user.password),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        return this.$store.state.views.login.dialogCreate;
      },
      set(value) {
        this.$store.state.views.login.dialogCreate = value;
      },
    },
  },
  methods: {
    searchEstabelecimento() {
      this.submitted = true;
      if (!this.estabelecimento.cnes) {
        return;
      } else {
        this.estabelecimentoService
          .findByCNES(this.estabelecimento.cnes)
          .then((data) => {
            this.estabelecimento = data;
            this.user.estabelecimento = [data];
            this.showDetails = true;
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Alerta de Erro.",
              detail:
                "CNES não encontrado. Informe seu CNES novamente de forma correta.",
              life: 6000,
            });
          });
      }
    },
    createUser(isFormValid) {
      this.user.grupo = { id: 2 };
      this.submitted = true;
      this.user.status = false;
      if (isFormValid) {
        this.userService
          .createusuarioAndPassword(this.user)
          .then((data) => {
            if (data.status === 201) {
              this.$toast.add({
                severity: "success",
                summary: "Alerta!",
                detail:
                  "Cadastrado com sucesso. Seu login é seu email: " +
                  this.user.email,
              });
              this.hideDialog();
            }
          })
          .catch((error) => {
            this.$msgErro(error);
          });
      } else {
        return;
      }
    },
    hideDialog() {
      //this.person = new Person();
      this.user = new Users();
      this.estabelecimento = new Estabelecimento();
      this.submitted = false;
      this.confirmPasword = null;
      this.showDetails = false;
      this.visibleDialog = false;
      this.widthDialog = "480px";
    },
  },
};
</script>
<style scoped></style>
