import axios from "axios";
import Token from "../../utilities/jwt/Token.js";

export default class EmailService extends Token {

    sendEmail(dados) {
        return axios({
            method: 'post',
            url: this.api_url + 'email',
            data: dados,
        });
    }
}
