import { required } from "@vuelidate/validators";

import Gestao from "./gestao";
import NaturezaJuridica from "./natureza_juridica";


export default class Estabelecimento {
  constructor() {
    this.id = null;
    this.nome = null;
    this.cnes = null;
    this.cnpj = null;
    this.contato = null;
    this.gestao = new Gestao();
    this.natureza = new NaturezaJuridica();
  }

  validations() {
    return {
      nome: {
        required,
      },
      cnes: {
        required,
      },
      cnpj: {
        required,
      },
      contato: {
        required,
      },
      gestao: {
        id: {
          required,
        }
      },
      natureza: {
        id: {
          required,
        }
      },
    };
  }


}
