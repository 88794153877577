import axios from "axios";
import Token from "../../utilities/jwt/Token.js";
import { gerarUrl } from "../../utilities/utils/Pageable";

export default class EstabelecimentoService extends Token {

  findAll(queryParams) {

    queryParams = gerarUrl(queryParams);

    return axios({
      method: "get",
      url: this.api_url + "estabelecimento?" + queryParams,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }
  findByCNES(cnes) {
    return axios({
      method: "get",
      url: this.api_url + "estabelecimento/" + cnes + "/cnes",
      withCredentials: true,
    }).then((res) => res.data);
  }

  findById(id) {
    return axios({
      method: "get",
      url: this.api_url + "estabelecimento/" + id,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }

  findByUsuarioId(id) {
    return axios({
      method: "get",
      url: this.api_url + "estabelecimento/usuario/" + id,
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    }).then((res) => res.data);
  }

  create(estabelecimento) {
    return axios({
      method: "post",
      url: this.api_url + "estabelecimento",
      withCredentials: true,
      data: estabelecimento,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
    });
  }
  update(estabelecimento) {
    return axios({
      method: 'put',
      url: this.api_url + 'estabelecimento/' + estabelecimento.id,
      withCredentials: true,
      data: estabelecimento,
      headers: {
        Authorization: "Bearer " + this.token(),
      },
      
    });
  }

  delete(id) {
    return axios({
      method: 'delete',
      url: this.api_url + 'estabelecimento/' + id,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      }
    });
  }
  
}
