import { required } from "@vuelidate/validators";

export default class NaturezaJuridica {

  constructor() {
    this.id = null;
    this.descricao = null;
  }

  validations() {
    return {
      descricao: {
        required,
      },
    };
  }

}
